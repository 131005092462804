import { h } from 'preact'

const Instruction = (status: number, tiltPosition: number, acceleration: any, setActiveInstruction: any, activeDescription: any, setActiveDescription: any) => {




	switch (status) {
		case 0:
			return (
				<div>
					<h2 className="font-semibold text-md">
						SOUND ON &
						<br /> VOLUME UP
					</h2>
					<img
						width="120px"
						className="mx-auto mt-2"
						src="assets/animations/unmute.gif"
					/>
				</div>
			)
		case 1:
			return (
				<div className="grid">
					<div className="flex gap-6 text-start">
						<img
							width="24px"
							src="assets/animations/skin-tight-long-hair.gif"
						/>
						<h2 className="font-semibold text-md">
							Wear skintight clothing and tie long hair up for accurate results
						</h2>
					</div>
					<div className="flex gap-6 mt-4">
						<img width="37px" src="assets/animations/simple-background.gif" />
						<div className="font-semibold text-md">Simple Background</div>
					</div>
				</div>
			)



		case 2:
			return (
				<div>
					<h1 className="font-bold mb-4 text-lg text-[#1D1C19]">Choose Phone Placement</h1>
				
				
				
					<div className="grid grid-cols-2 gap-2">
					<div className="relative">
						<label className='absolute -top-2 px-2 border border-black rounded-md bg-white uppercase z-10 font-semibold text-[10px] '>Recommended</label>
    <button
        onClick={() => {
            setActiveInstruction(status + 1)
            setActiveDescription("object")
        }}
        className="bg-[#000000] w-full p-3 text-sm h-[68px] text-white font-semibold uppercase  rounded-md"
    >
        AGAINST OBJECT TABLE Height
    </button>
</div>


						<div className="">
							<button
								onClick={() => {
									setActiveInstruction(status + 1)
									setActiveDescription("wall")

								}}
								className="bg-[#000000] w-full p-3 h-[68px] text-sm text-white font-semibold uppercase rounded-md"
							>
								AGAINST WALL ON FLOOR
							</button>
						</div>
					</div>
				</div>
			)

		case 3:
			return (
				<div className="flex gap-4">
					<div>
					<div className={"relative " + (activeDescription == 'wall' ? "py-8" : "py-1") + " bg-gray-300 w-[15px] h-52 rounded-full justify-center"} >

					{/* <div className="relative py-8 bg-gray-300 w-[15px] h-52 rounded-full justify-center">
				 */}
					<div className="relative z-0 bg-green-500 w-[15px] h-7"></div>
					
							<div
								className="absolute z-1 bg-black rounded-full w-[15px] h-[15px]"
								style={{ top: `${tiltPosition}px` }}
							></div>
						</div>
					</div>
					<div>
						<img
							className="mx-auto w-20"
							src="assets/animations/tilt-phone.gif"
						/>
					<h2 className="font-semibold text-lg mt-3 px-3">
							{/* <p>Acceleration Value:</p> */}
							{activeDescription == 'wall' ? "Lean your phone against wall on the floor & tilt it until dot is in the green area" : activeDescription == "object" ? "Lean your phone against an object table height until dot is in the green" : ""}
						</h2>
					</div>
				</div>
			);

		case 4:
			return (
				<div>
					<img className="mx-auto w-20" src="assets/animations/step-back.gif" />
					<h2 className="font-semibold text-md mt-2 px-6">
						Take 2 steps back. Arms and legs apart. On the beep, rotate 360°
						slowly
					</h2>
				</div>
			)
		default:
			break
	}
}

export default Instruction
